import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import {
  APP_INITIALIZER,
  type ApplicationConfig,
  type EnvironmentProviders,
  ErrorHandler,
  LOCALE_ID,
  type Provider,
  importProvidersFrom,
  inject,
  provideZoneChangeDetection,
} from '@angular/core';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withInMemoryScrolling, withRouterConfig } from '@angular/router';

import { type Observable, map } from 'rxjs';

import { NAVIGATOR } from '@ng-web-apis/common';
import { provideEffects } from '@ngrx/effects';
import { provideStore } from '@ngrx/store';
import { TUI_DATE_FORMAT, TUI_DATE_SEPARATOR, type TuiDateMode } from '@taiga-ui/cdk';
import {
  TUI_SANITIZER,
  TUI_SVG_DEFAULT_OPTIONS,
  TuiAlertModule,
  TuiDialogModule,
  TuiRootModule,
  tuiSvgOptionsProvider,
} from '@taiga-ui/core';
import { NgDompurifySanitizer } from '@tinkoff/ng-dompurify';

import {
  type AccountActivationValidatorConfig,
  AuthenticationFacadeService,
  AuthenticationTokenStorageService,
  authEffects,
  authFeature,
  provideFinAuthenticationConfiguration,
  userActivityEffects,
  userActivityFeature,
} from '@finverity/authentication';
import { provideFinFileUploaderConfiguration } from '@finverity/file-uploader';
import { provideFinApolloGraphqlOptions } from '@finverity/graphql';
import { type Policy, provideFinIdentityAccessPolicies } from '@finverity/identity-access';
import { APP_ROUTES } from '@finverity/platform-app/app.routes';
import { FirebaseCloudMessagingService } from '@finverity/platform-app/core/firebase';
import {
  authInterceptor,
  blobErrorInterceptor,
  errorHandlerInterceptor,
} from '@finverity/platform-app/core/http-interceptors';
import { SettingsModule } from '@finverity/platform-app/core/modules/settings';
import { SentryErrorHandlerService, SentryReporterService } from '@finverity/platform-app/core/sentry';
import { FinEnvironmentService, NotificationsService } from '@finverity/platform-app/core/services';
import { debugActionsMetaReducer } from '@finverity/platform-app/core/store';
import {
  provideFinConfirmDialog,
  provideFinDialog,
  provideFinInfoDialog,
  provideFinPlatformAppUiKitDefaultOptions,
} from '@finverity/ui-kit';

const ANGULAR_SPECIFIC_PROVIDERS: Array<Provider | EnvironmentProviders> = [
  provideZoneChangeDetection(),
  provideAnimations(),
  provideHttpClient(withFetch(), withInterceptors([errorHandlerInterceptor, blobErrorInterceptor, authInterceptor])),
  provideRouter(APP_ROUTES, withRouterConfig({}), withInMemoryScrolling({ scrollPositionRestoration: 'enabled' })),
  importProvidersFrom(
    provideFirebaseApp(() => {
      const environmentService = inject(FinEnvironmentService);
      return initializeApp(environmentService.firebase);
    })
  ),
  importProvidersFrom(provideMessaging(() => getMessaging(getApp()))),
  {
    provide: LOCALE_ID,
    useFactory: () => {
      const navigator = inject<Navigator>(NAVIGATOR);
      return navigator.language.startsWith('en') ? navigator.language : 'en-US';
    },
  },
];

const TUI_SPECIFIC_PROVIDERS: Array<Provider | EnvironmentProviders> = [
  { provide: TUI_SANITIZER, useClass: NgDompurifySanitizer },
  importProvidersFrom(TuiRootModule, TuiAlertModule, TuiDialogModule),
  tuiSvgOptionsProvider({
    path: name => {
      if (name.startsWith(`fvIcon`)) {
        return `assets/svg-icons/${name}.svg#${name}`;
      }
      return TUI_SVG_DEFAULT_OPTIONS.path(name);
    },
    srcProcessor: src => {
      const iconName = String(src);
      if (iconName.startsWith(`fvIcon`)) {
        return `assets/svg-icons/${iconName}.svg#${iconName}`;
      }
      return TUI_SVG_DEFAULT_OPTIONS.srcProcessor(src);
    },
  }),
  {
    provide: TUI_DATE_FORMAT,
    useFactory: (): TuiDateMode => {
      const local = inject(LOCALE_ID);
      return local === 'en-US' ? 'MDY' : 'DMY';
    },
  },
  {
    provide: TUI_DATE_SEPARATOR,
    useValue: '/',
  },
  provideFinPlatformAppUiKitDefaultOptions(),
];

const NGRX_SPECIFIC_PROVIDERS: Array<Provider | EnvironmentProviders> = [
  provideStore(
    {
      [authFeature.name]: authFeature.reducer,
      [userActivityFeature.name]: userActivityFeature.reducer,
    },
    {
      runtimeChecks: {
        strictActionSerializability: false,
        strictActionTypeUniqueness: true,
        strictActionImmutability: true,
        strictActionWithinNgZone: true,
        strictStateSerializability: false,
        strictStateImmutability: true,
      },
      metaReducers: [debugActionsMetaReducer],
    }
  ),
  provideEffects(authEffects, userActivityEffects),
  // Error: NG0200: Circular dependency in DI detected for ErrorHandler. Find more at https://angular.io/errors/NG0200
  // provideStoreDevtools({ name: 'Finverity Platform App' }),
];

const APP_SPECIFIC_PROVIDERS: Array<Provider | EnvironmentProviders> = [
  provideFinAuthenticationConfiguration(() => {
    const environmentService = inject(FinEnvironmentService);
    return {
      isDevelopment: !environmentService.production,
      SSOUrl: environmentService.api.SSOUrl,
      maxUserInactivityMinutes: environmentService.api.maxUserInactivityMinutes,
      platform: {
        companyName: environmentService.platform.company,
        companyAddress: environmentService.platform.companyAddress,
        displayUrl: environmentService.platform.displayUrl,
        logo: environmentService.platform.logo.default,
        validators: environmentService.platform.validators as AccountActivationValidatorConfig[],
        supportEmail: environmentService.platform.email,
        ssoAuth: {
          redirectPath: environmentService.api.ssoAuth?.redirectPath || '',
        },
      },
    };
  }),
  provideFinFileUploaderConfiguration(() => {
    const environmentService = inject(FinEnvironmentService);
    return {
      fileServerUrl: environmentService.api.fileServerUrl,
    };
  }),
  provideFinIdentityAccessPolicies(() => inject(AuthenticationFacadeService).userPolicies$ as Observable<Policy[]>),
  provideFinApolloGraphqlOptions(() => {
    const environmentService = inject(FinEnvironmentService);
    const notificationsService = inject(NotificationsService);
    const sentryReporterService = inject(SentryReporterService);

    return {
      apiUrl: environmentService.api.gatewayUrl,
      wsUrl: environmentService.api.gatewayWSUrl,
      graphqlErrorNotifier: notificationsService,
      graphqlErrorReporter: sentryReporterService,
      authTokenFactory: () => {
        const authFacade = inject(AuthenticationFacadeService);
        const authTokenStorageService = inject(AuthenticationTokenStorageService);
        return authFacade.user$.pipe(map(() => authTokenStorageService.getToken()));
      },
    };
  }),
  provideFinDialog(),
  provideFinConfirmDialog(),
  provideFinInfoDialog(),
  importProvidersFrom(SettingsModule),
  {
    provide: ErrorHandler,
    useFactory: () =>
      inject(FinEnvironmentService).sentry.enabled ? inject(SentryErrorHandlerService) : new ErrorHandler(),
  },
  {
    provide: APP_INITIALIZER,
    useFactory: (authFacadeService: AuthenticationFacadeService) => () => authFacadeService.initAuthUser(),
    deps: [AuthenticationFacadeService],
    multi: true,
  },
  {
    provide: APP_INITIALIZER,
    useFactory: (messagingService: FirebaseCloudMessagingService) => () => messagingService.init(),
    deps: [FirebaseCloudMessagingService],
    multi: true,
  },
];

export const appConfig: ApplicationConfig = {
  providers: [ANGULAR_SPECIFIC_PROVIDERS, TUI_SPECIFIC_PROVIDERS, NGRX_SPECIFIC_PROVIDERS, APP_SPECIFIC_PROVIDERS],
};
